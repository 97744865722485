<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <h1>Invoices</h1>
        <v-spacer></v-spacer>
        <CreateInvoice /> 
        <v-btn to="/invoice-report">Create a report</v-btn>
      </v-card-title>

      <v-card-text>
        <InvoiceList :invoices="invoices" :paginated=true title="All Invoices"></InvoiceList>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import InvoiceList from "@/components/invoice/InvoiceList";
import CreateInvoice from "@/components/invoice/CreateInvoice";

export default {
  components: {
    InvoiceList, CreateInvoice
  },
  computed: {
    invoices() {
      return this.$store.getters.sortedInvoices;
    },
  },
  mounted() {
    this.$store.dispatch("loadInvoices");
    this.$store.dispatch("clearInvoice");
  },
};
</script>