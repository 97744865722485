<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on">New Invoice</v-btn>
    </template>
    <v-card>
      <v-card-title class="headline">Select client and create empty invoice</v-card-title>
      <v-card-text>
        <v-autocomplete
          class="client-select"
          v-model="select"
          :items="clients"
          item-text="name"
          item-value="_id"
          :error-messages="selectErrors"
          label="Client"
          required
          @change="$v.select.$touch()"
          @blur="$v.select.$touch()"
        ></v-autocomplete>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="cancelDialog">Cancel</v-btn>
        <v-btn color="green darken-1" text @click="createInvoice">Create Invoice</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { DateTime } from "luxon";

export default {
  mixins: [validationMixin],

  validations: {
    select: { required },
  },
  data: () => ({
    itemCount: 0,
    toggleDatePicker: false,
    isHourly: false,
    isFixed: false,
    invoiceNumber: "",
    items: [],
    select: null,
    status: "draft",
    taxRate: 0,
    taxLabel: "",
    taxAccount: "",
    menuStart: null,
    statusOptions: ["sent", "received", "draft"],
    hourlyRate: 0,
    dialog: false,
  }),
  computed: {
    prefs() {
      return this.$store.getters.preferences;
    },
    clients() {
      return this.$store.getters.sortedClients;
    },
    client() {
      let index = this.clients.findIndex((client) => client._id == this.select);
      let client = this.clients[index];
      if (client) {
        return client;
      } else {
        return {};
      }
    },
    selectErrors() {
      const errors = [];
      if (!this.$v.select.$dirty) return errors;
      !this.$v.select.required && errors.push("Client is required");
      return errors;
    },
  },

  methods: {
    setDefaultDate() {
      let dt = DateTime.local();
      return dt;
    },
    createInvoiceNumber() {
      let dt = DateTime.local();
      let seconds = dt.toSeconds();
      let trunc = parseInt(seconds);
      return trunc.toString();
    },
    createInvoice() {
      this.$v.$touch();
      if (!this.$v.$anyError) {
        let payload = {
          amount: 0,
          client: this.client._id,
          clientName: this.client.name,
          items: this.items,
          invoiceNumber: this.invoiceNumber,
          date: this.setDefaultDate(),
          status: this.status,
          taxRate: this.taxRate,
          tax: 0,
          totalAmount: 0,
        };
        this.$store.dispatch("createInvoice", payload);
        this.dialog = false;
        this.select = null;
      } else {
        this.$store.dispatch("setError", {
          data: { message: "Fix the highlighted fields and try again" },
        });
      }
    },
    cancelDialog() {
      this.$v.$reset();
      this.select = null;
      this.dialog = false;
    },
  },
  mounted() {
    this.$store.dispatch("loadClients");
    this.invoiceNumber = this.createInvoiceNumber();
    this.taxRate = this.prefs.taxRate || 0;
    this.hourlyRate = this.prefs.hourlyRate || 0;
  },
};
</script>

<style scoped>
</style>